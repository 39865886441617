
import { finishFlow } from '@/api/flowSession';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import { getNextPage } from '@/utils/redirectUtils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    BaseButton,
  },
})
export default class ThankYouPage extends Vue {
  isButtonPreloaderShow = false;

  async finishApplicationAction(): Promise<void> {
    this.isButtonPreloaderShow = true;
    try {
      this.$router.push({ name: getNextPage() });
      finishFlow();
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isButtonPreloaderShow = false;
    }
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }
}
